$box-border-radius: 30px;
.ac-settings {
  position: relative;
  padding-left: 28%;
  padding-right: 28%;
  color: white;
  @media (max-width: $mobile) {
    padding-left: 15%;
    padding-right: 15%;
  }

  .ac-text-field {
    position: relative;
    height: 48px;
    @media (max-width: $mobile) {
      height: 42px;
    }
  }

  .error-text {
    height: 4vh;
    color: yellow;
    font-size: 12px;
    text-align: left;
    margin-left: 13px;
    z-index: 1;
  }
}
