$box-border-radius: 30px;
.ac-register-teacher {
  position: relative;
  padding-top: 10vh;
  padding-left: 20%;
  padding-right: 20%;
  @media (max-width: $mobile) {
    padding-top: 10vh;
    padding-left: 10%;
    padding-right: 10%;
  }

  &__title {
    font-size: 25px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
  }

  &__subtitle {
    color: white;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 50px;
    @media (max-width: $mobile) {
      font-size: 12.85px;
    }
  }

  &__subtitle-login {
    color: white;
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__btn {
    text-align: center;
    .v-btn {
      height: 48px;
      border-radius: $box-border-radius;
      margin: 0;
    }
  }

  .ac-text-field {
    position: relative;
    height: 48px;
    @media (max-width: $mobile) {
      height: 42px;
    }
  }

  .register-teacher-icon {
    fill: white;
    stroke: none;
    position: absolute;
    left: -80px;
    &.user {
      stroke: white;
      fill: none;
    }
  }

  &__error {
    // position: absolute;
    // top: 100%;
    // left: 50%;
    // transform: translate(-50%);
    text-align: center;
    margin-top: 1rem;
  }

  .btn-login {
    width: 60%; // Fix IE 11 issue.
    font-size: small;
    height: 48px;
    margin-bottom: 10px;
    background: linear-gradient(to right, #8c00ff 0%, #1f409d 100%); //dark-purple
    &:hover {
      opacity: 0.9;
    }
  }

  .btn-send {
    width: 70%; // Fix IE 11 issue.
    font-size: small;
    height: 48px;
    background: linear-gradient(to right, $light-blue-header 0%, #1f409d 100%); //dark-purple
    &:hover {
      opacity: 0.9;
    }
  }

  .error-text {
    height: 4vh;
    color: yellow;
    font-size: 12px;
    text-align: left;
    margin-left: 13px;
    z-index: 1;
  }
}
