.refresh {
  // margin-top: 70px;
  background-color: #eed879;
  height: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  &--title {
    font-weight: 400;
    font-size: 12px;
    color: $blue-dianne;
    margin-right: 10px;
  }
  &--button {
    background-color: #fff0ad !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35);
    border-radius: 5px !important;
    width: 40px;
    height: 18px;
  }
  &--button-icon {
    color: $blue-dianne;
    height: 20px !important;
  }
}
