.multiline-text-field {
  background-color: white;
  border-radius: 10px;
}

.multiline-post-btn {
  background-color: white !important;
  color: black !important;
  height: 30px;
  width: 100px;
  font-size: 12px !important;
  &:disabled {
    opacity: 0.5;
  }
}
