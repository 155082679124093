.pick-card {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__subtext {
    font-weight: 500;
    font-size: large;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: white;
    margin-bottom: 20px;
  }
}
