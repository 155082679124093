.slide-stats-bar {
  display: flex;
  align-items: center;
  margin: 10px 0;

  > * {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__item {
    width: 20%;
    text-align: center;
    font-size: 8px;
    min-width: 40px;

    color: white;
    &__bar {
      height: 10px;
      > span {
        font-weight: bold;
      }
    }

    @each $stat, $val in $stats-map {
      &--#{$stat} {
        .slide-stats-bar__item__bar {
          background-color: grey;
          color: black;

          &__progress {
            display: flex;
            height: 100%;
            margin-top: -10px;
            background-image: map-get($val, "background");
            transition: width 500ms ease-in-out;
          }
        }

        .slide-stats-bar__item__text {
          color: #62daff;
          font-weight: 600;
          text-transform: uppercase;
          line-height: 2;
        }
      }
    }
  }

  &--small {
    .slide-stats-bar__item {
      min-width: 12px;
      &__bar {
        height: 4px;
      }
    }
  }
}
