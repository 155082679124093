.stats {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }

  &__subtext {
    font-weight: 500;
    font-size: large;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: white;
  }
  &__showAll {
    font-weight: 500;
    font-size: large;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: white;
    font-size: 14px;
  }
}
