@import "../styles/variables";

.ac-card-stats {
  width: 160px;
  border-radius: 10px !important;
  border: 2px solid #1b3447;

  &-header-icon {
    width: 12px !important;
    height: 12px !important;
    color: white;
    &-live {
      width: 12px !important;
      height: 12px !important;
      color: darkslategray;
    }
  }

  &-header-title {
    font-weight: 700 !important;
    font-size: 14px !important;
    color: white;
  }

  &-header-subtitle {
    font-size: 10px !important;
    color: white;
  }

  &-button-action {
    height: 10px !important;
    font-weight: 700 !important;
    font-size: 9px !important;
    margin-bottom: 5px !important;
  }

  &-body-content {
    height: 40px;
    padding: 8px !important;
  }

  &-body-text {
    font-size: 10px !important;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}
