.download-password {
  padding-left: 1vh;
  padding-right: 1vh;
  color: white;
  &_main-text {
    font-size: 25px !important;
    font-weight: bold !important;
  }
  &_second-text {
    font-size: 18px !important;
  }
  &_join-class {
    margin-left: 10px !important;
  }
  @media (max-width: 800px) {
    padding-top: 22vh;
  }
}
