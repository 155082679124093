.question-card {
  &-container {
    border-radius: 10px !important;
    background-color: $question-color !important;
  }

  &-container-not-read {
    background-color: #2c549e !important;
    cursor: pointer;
  }

  &-history {
    width: 10px !important;
    padding: 0px !important;
    margin-left: -8px !important;
    margin-top: -8px !important;
    font-size: 13px !important;
  }
}
