.create-course {
  text-align: center;

  &__title {
    font-size: 14px;
    text-align: center;
    color: #ffffff;
  }

  &__text-field {
    height: 48px;
  }

  .error-text {
    height: 4vh;
    color: yellow;
    font-size: 12px;
    text-align: left;
    margin-left: 13px;
    margin-top: 2px;
    z-index: 1;
  }

  .btn-send {
    width: 40%; // Fix IE 11 issue.
    font-size: small;
    height: 36px;
    background: linear-gradient(to right, #25b401 0%, #b0fe54 100%); //dark-purple
    &:hover {
      opacity: 0.9;
    }
  }

  .btn-delete {
    margin-left: 20px;
    width: 40%; // Fix IE 11 issue.
    font-size: small;
    height: 36px;
    background: $red-solid;
    &:hover {
      opacity: 0.9;
    }
  }
}
