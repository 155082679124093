.teacher-layout {
  padding: 15px;
  padding-top: 80px;

  &__subheader {
    width: 100%;
    min-height: 30px;
    // display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    text-align: center;
  }

  &__title {
    flex: 1 1 auto;
  }
}
