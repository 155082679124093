.ac-nav {
  background: linear-gradient(90deg, $dark-blue-header -23.7%, $light-blue-header 57.96%);
  color: white;
  height: 3.5rem;
  display: flex !important;
  flex-direction: row !important;

  @media (max-width: $mobile) {
    height: 3.5rem;
  }

  &__items {
    padding-top: 5%;

    @media (max-width: $mobile) {
      padding-top: 7%;
    }

    a {
      justify-content: flex-end;
      font-size: 20px !important;
      color: white !important;
      line-height: 2em;
      &.v-btn--active::before {
        opacity: 0;
      }
    }
  }

  &__logo {
    position: relative;
    bottom: 1px; //3px;
    width: 22px !important;
    height: 21px !important;

    @media (max-width: $mobile) {
      vertical-align: middle;
      width: 31px !important;
      height: 30px !important;
    }

    @media (max-width: $tablet) {
      width: 28px !important;
      height: 27px !important;
    }
  }

  &__title-text {
    display: inline-block;
    position: relative;
    font-size: 20px;
    margin-left: 2px;
    font-weight: 1000;
    vertical-align: text-top;
  }

  &__live-btn {
    background-color: #ff4848 !important;
    padding: 0.2 !important;
    line-height: 1 !important;
    border-radius: 5px !important;
    height: 25px;
    width: 84px;
    border-radius: 7px !important;
    font-weight: 500 !important;
    font-size: 17px !important;
    &:hover {
      opacity: 0.9 !important;
    }
  }
}

.col-item {
  padding: 0 !important;
}

.ac-nav__title {
  cursor: pointer;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 24px;
  line-height: normal;
  .ac-small {
    font-weight: 300;
  }
}

.nav_container {
  width: 80%;
  height: 100% !important;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 10%;
  margin-right: 10%;
  align-items: center;
  @media (max-width: $mobile) {
    width: 90%;
    margin-left: 1%;
    margin-right: 5%;
  }
}

.item1 {
  //border: 1px solid yellow;
  height: 100%;
  bottom: 10%;
}

.item2 {
  //border: 1px solid green;
  justify-self: center;
  font-size: 20px;
  height: 100%;
}

.item3 {
  //border: 1px solid blue;
  justify-self: end;
  height: 100%;

  @media (max-width: $mobile) {
    padding-top: 0%;
  }
}

.join-icon {
  border: 1px white solid;
  margin-right: 7px;

  @media (max-width: $mobile) {
    margin-right: 7px;
  }
}

.notification-icon {
  @media (max-width: $mobile) {
    margin-right: 7px;
  }
}

.v-btn .v-badge__badge {
  transform: translate(-8px, 8px);
}

.drawer {
  width: 30% !important;

  @media (max-width: $mobile) {
    width: 100% !important;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    //margin-bottom: 16px;
    padding: 12px;
    background: black;
  }

  .v-navigation-drawer {
    &__content {
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .student-sidebar,
      .teacher-sidebar {
        flex: 1;
        margin-top: 0;
      }
    }
  }
}

.header-drawer-list {
  .menu_icon {
    margin-right: 24px;
  }

  .horizontal_line {
    background-color: white;
    width: 90%;
    height: 1px;
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .menu_label {
    font-size: 14px;
  }

  .menu_link {
    display: flex;
    width: 100%;
    align-items: center;
    color: white;
    text-decoration: none;

    &__selected {
      display: flex;
      width: 100%;
      align-items: center;
      color: $blue-dianne;
      text-decoration: underline;
      font-size: 14px !important;
      font-weight: 600 !important;
      border-radius: 5px;
      background: $answer-color;
      .menu_label {
        font-weight: 700 !important;
      }
    }
  }
}

.row {
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: -12px;
  margin-left: -12px;
}

.col {
  height: 100%;
}
