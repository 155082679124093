.remote-item {
  max-height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.remote-item--GREAT {
    @media (max-width: $mobile) {
      transform: translateY(-0px);
    }
  }

  .ac-prgress-bar__info {
    cursor: pointer;
  }

  $reactions: ("rew", "lost", "great", "pause", "ff");

  @each $reaction in $reactions {
    &--#{$reaction} {
      .v-progress-circular__overlay {
        stroke: url(#linear-gradient-#{$reaction});
      }
    }
  }

  &__title {
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #62daff;

    @media (max-width: $mobile) {
      font-size: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  &--voted &__title {
    color: #f1f9fc;
    border-bottom: 1px solid white;
    padding-bottom: 1px;
  }
}
