.discussion-card {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .discussion-btn {
    height: 29px;
    border-color: $navy-solid;
    background-color: $discussion-color;
    border-radius: 6px;
    font-size: 10px;
    padding: 3px;
    @media (max-width: $mobile) {
      font-size: 8px;
      padding: 0;
    }
    &.MuiButton-root:hover {
      background-color: $discussion-color;
    }
  }
  .discussion-btn-active {
    height: 29px;
    border-color: white;
    background-color: $discussion-color;
    border-radius: 6px;
    font-size: 10px;
    padding: 3px;
    @media (max-width: $mobile) {
      font-size: 8px;
      padding: 0;
    }
    &.MuiButton-root:hover {
      background-color: $discussion-color;
    }
  }
}
