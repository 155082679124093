.create-lecture {
  text-align: center;

  &__title {
    font-size: 15px;
    margin-bottom: 3px;
    text-align: center;
    color: #ffffff;
  }

  &__heading {
    font-size: 22px;
    text-align: center;
    color: #ffffff;
  }

  &__text-field {
    height: 48px;
    color: $blue-dianne !important;
    font-size: 14px !important;
  }

  &__black-title {
    font-size: 15px;
    text-align: center;
    color: $blue-dianne;
  }

  .error-text {
    height: 4vh;
    color: yellow;
    font-size: 12px;
    text-align: left;
    margin-left: 13px;
    margin-top: 2px;
    z-index: 1;
  }

  .btn-send {
    width: 40%; // Fix IE 11 issue.
    font-size: small;
    height: 48px;
    background: $green-solid;
    border: 1px solid;
    &:hover {
      opacity: 0.9;
      background: $green-solid;
    }
  }

  .btn-upload-presentation {
    width: 100%; // Fix IE 11 issue.
    font-size: small;
    background: white;
    display: flex;
    justify-content: space-between;
    height: 48px;
    &:hover {
      opacity: 0.9;
      background: white;
    }
  }

  .btn-presentation-uploaded {
    width: 100%; // Fix IE 11 issue.
    font-size: small;
    background: white;
    color: black;
    height: 48px;
    display: flex;
    justify-content: space-between;
    &:hover {
      opacity: 0.9;
      background: white;
    }
  }

  .btn-delete {
    margin-left: 20px;
    width: 40%; // Fix IE 11 issue.
    font-size: small;
    height: 48px;
    background: $red-solid;
    border: 1px solid;
    &:hover {
      opacity: 0.9;
      background: $red-solid;
    }
  }

  .btn-delete-presentation {
    width: 100%; // Fix IE 11 issue.
    font-size: small;
    height: 48px;
    background: $red-solid;
    border: 1px solid;
    &:hover {
      opacity: 0.9;
      background: $red-solid;
    }
  }

  .btn-preview-presentation {
    width: 100%; // Fix IE 11 issue.
    font-size: small;
    height: 48px;
    background: #1ca3ff;
    border: 1px solid;
    &:hover {
      opacity: 0.9;
      background: #1ca3ff;
    }
  }

  .presentation-info-text {
    color: #fff !important;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    width: 90%;
    margin-top: 6px;
  }

  .presentation-info-text-big {
    color: #fff !important;
    text-align: left;
    font-size: 15px;
    min-height: 17px;
  }
}
