@import "../styles/variables";

.waiting-room {
  padding-top: 25vh;

  &__return-button {
    background-color: $yellow-solid !important;
    color: black !important;
  }
}
