.login-layout {
  background-image: url("../assets/icons/bckg-small.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  min-width: 100vw;
  text-align: center;
  &__overlay {
    @extend .login-layout;
    background: transparent linear-gradient(180deg, #231f20b2 0%, #2b388e 100%) 0% 0% no-repeat padding-box;
  }

  &__content {
    position: relative;
    margin: 0 auto;
    margin-top: 40px;
    width: 100%;
  }
}
