@import "../styles/variables";

.anon-card {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: $dark-blue-header !important;
  border: 1px solid #73ad21;

  &:hover {
    transition: all 0.15s ease-in;
    background-color: #0058cc !important;
    cursor: pointer;
  }

  &-icon {
    @media (max-width: 600px) {
      transform: scale(2.2);
      &:hover {
        transition: all 0.2s ease-in;
        transform: scale(2.5);
      }
    }
    @media (min-width: 600px) and (max-width: 900px) {
      transform: scale(2.2);
      &:hover {
        transition: all 0.2s ease-in;
        transform: scale(2.5);
      }
    }
    @media (min-width: 900px) and (max-width: 1200px) {
      transform: scale(3.2);
      &:hover {
        transition: all 0.2s ease-in;
        transform: scale(3.5);
      }
    }
    @media (min-width: 1200px) {
      transform: scale(3.2);
      &:hover {
        transition: all 0.2s ease-in;
        transform: scale(3.5);
      }
    }
  }
}
