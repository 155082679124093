.answer-card {
  position: relative;
  display: flex;
  justify-content: center;
  &-container {
    border-radius: 10px !important;
    background-color: $answer-color !important;
  }
  &-container-not-read {
    background-color: #82c5fc !important;
    cursor: pointer;
  }

  &-btn-show-answers {
    position: absolute !important;
    width: 125px;
    height: 29px;
    top: -15px;
    align-self: center;
    z-index: 1;

    font-size: 10px !important;

    background-color: $answer-color !important;
    border: 2px solid $blue-dianne !important;
    border-radius: 13px !important;
    color: $blue-dianne !important;
  }

  &-question-order {
    position: absolute;
    right: 8px;
    z-index: 1;
    margin-top: 5px;
    cursor: pointer;
  }
  &-question-order-active {
    background: $answer-color;
    margin-top: -30px;
    margin-right: -8px;
    padding: 8px;
    border-top-left-radius: 8px;
  }

  &-history {
    width: 10px !important;
    padding: 0px !important;
    margin-left: -8px !important;
    margin-top: -8px !important;
    font-size: 13px !important;
  }
}
