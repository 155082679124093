@import "../styles/variables";

.statistics-admin {
  text-align: center;

  &__subtext {
    font-weight: 500;
    font-size: large;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: white;
    margin-bottom: 20px;
  }
  &__card {
    width: 100%;
    background-color: $blue-dianne !important;
    border: 1px solid #ffffff;
    cursor: pointer;
    border-radius: 10px !important;
    &:hover {
      background-color: $navy-solid !important;
    }
  }
  &__column-title {
    font-size: 13;
  }
}
