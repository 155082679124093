.more-less-text {
  .button-text {
    padding: 0;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    line-height: normal;
    letter-spacing: normal;
  }

  .long-text {
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    font-size: 13px;
  }

  .clamp {
    -webkit-line-clamp: 2;
    @media (max-width: $tablet) {
      -webkit-line-clamp: 3;
    }
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }

  .more-less-button {
    background-color: #f5f5f5;
    color: $blue-dianne;
    border-radius: 6px;
    width: 69px;
    height: 16px;
    font-size: 9px;
    outline: none;
    border: 1px solid $blue-dianne;
    padding: 0;
    padding-left: 2px;
    & .MuiButton-endIcon {
      margin-left: 0;
    }
  }
}
