.ac-progress-bar {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 60px;
  min-height: 60px;

  &__value {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(calc(-50% + 0.3em));
    line-height: 1.2;
    color: #d0d2d3;
    font-size: 12px;
    font-weight: bold;
    padding-right: 4px;
  }

  &__info {
    width: 100% !important;
    height: 100%;
    display: grid;
    place-items: center;
  }
}

.v-progress-circular {
  &__underlay {
    // stroke: #14c5fb;
    stroke: #39a5c0;
  }

  // &__overlay {
  //   stroke: url(#linear-gradient);
  // }
}
