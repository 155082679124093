.my-courses {
  text-align: center;

  &__empty {
    color: white;
    font-size: 20px;
    padding: 40px;
  }

  .course-card {
    &--active {
      border: 2px solid var(--v-green-base) !important;
    }

    &__button {
      width: 100%;
      display: block;
    }

    &__button-edit {
      height: 20px;
      position: relative;
      top: -10px;
      float: right;
      align-content: center;
    }

    &__subtext {
      font-weight: 500;
      font-size: large;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: white;
      margin-bottom: 10px;
    }

    &__fake-card {
      width: 160px !important;
      margin-left: 10px !important;
    }
  }
}
