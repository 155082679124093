.teacher-sidebar {
  display: flex;
  flex-direction: column;
  padding-top: 42px;
  align-items: flex-end;

  &__card {
    width: 180px;
    border-radius: 10px;
    background-color: #1f6c8e;
    margin-bottom: 8px;

    &__icon {
      margin-right: 24px;
    }
    &__label {
      font-size: 14px;
    }
  }

  &__card-title {
    color: white;
    text-align: center;
    padding: 8px;
  }

  &__link {
    display: flex;
    width: 100%;
    align-items: center;
    color: white;
    text-decoration: none;

    &__selected {
      display: flex;
      width: 100%;
      align-items: center;
      color: $blue-dianne;
      text-decoration: underline;
      font-size: 14px !important;
      font-weight: 600 !important;
      background: $answer-color;
      border-radius: 5px;
      .teacher-sidebar__card__label {
        font-weight: 700 !important;
      }
    }
  }

  &__circle {
    border-radius: 100px;
    border: 1px solid white;
    width: 24px;
    height: 24px;
  }
}
