.presentation-fullscreen {
  border-radius: 5px !important;
  font-size: 12px !important;
  margin-right: 15px !important;
  border-color: $answer-color !important;
  color: $answer-color !important;
  letter-spacing: 0.0892857143em !important;
  padding: 7px 15px 7px 15px !important;
}

.presentation-class-status {
  border-radius: 5px !important;
  font-size: 12px !important;
  letter-spacing: 0.0892857143em !important;
  padding: 7px 15px 7px 15px !important;
}
