$box-border-radius: 30px;
.ac-login {
  position: relative;

  &__title {
    color: white;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
    &--small {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  &__description {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  &__explanation {
    color: white;
    margin-top: 20px;
    font-size: 12px;
  }

  &__btn {
    text-align: center;
    .v-btn {
      height: 48px;
      border-radius: $box-border-radius;
      margin: 0;
    }
  }

  .ac-text-field {
    position: relative;
    height: 48px;
  }

  .login-icon {
    fill: white;
    stroke: none;
    position: absolute;
    left: -80px;
    &.user {
      stroke: white;
      fill: none;
    }
  }

  &__error {
    // position: absolute;
    // top: 100%;
    // left: 50%;
    // transform: translate(-50%);
    text-align: center;
    margin-top: 1rem;
  }

  .btn-login {
    width: 100%; // Fix IE 11 issue.
    font-size: small;
    height: 48px;
    margin-bottom: 10px;
    background: linear-gradient(to right, #8c00ff 0%, #1f409d 100%); //dark-purple
    &:hover {
      opacity: 0.9;
    }
  }

  .error-text {
    height: 4vh;
    color: yellow;
    font-size: 12px;
    text-align: left;
    margin-left: 13px;
    z-index: 1;
  }
}
