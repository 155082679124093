.lecture-questions {
  &_answer_btn {
    height: 30px !important;
    min-width: 130px;
    padding: 0 !important;
  }

  .input-comment {
    background-color: white;
    border-radius: 4px;
    padding: 0 0 0 6px;
  }

  .post-btn {
    background-color: white;
    color: black;
    height: 30px;
    width: 100px;
    font-size: 12px;
    &:disabled {
      opacity: 0.5;
    }
  }

  &-reaction-button {
    background-color: $green-light !important;
    max-height: 30px;
    min-width: 50px !important;
    color: white;
    &__dislike {
      background-color: #ec1c24 !important;
    }
  }

  &-reaction-icon {
    color: white;
    margin-right: -10px;
    margin-top: 10px;
    &__dislike {
      margin-right: 0px;
      margin-left: -10px;
      margin-top: -10px;
    }
    &__answer {
      margin-right: -20px;
      margin-top: 15px;
    }
  }
}
