.discussion-dialog {
  overflow-x: hidden;
  &-chat-container {
    font-size: 0.8rem;
    background-color: #f5f5f5;
    max-height: 50vh;
    overflow-y: auto;
  }

  .discussion-btn {
    height: 29px;
    border-color: $navy-solid;
    background-color: $discussion-color;
    border-radius: 6px;
    font-size: 10px;
    padding: 3px;
    @media (max-width: $mobile) {
      font-size: 8px;
      padding: 0;
    }
    &.MuiButton-root:hover {
      background-color: $discussion-color;
    }
  }

  &-chat-box {
    &-right {
      border-radius: 10px;
      color: $blue-dianne;
      background-color: #d4e2e2;
    }
    &-left {
      border-radius: 20px;
      color: $blue-dianne;
      background-color: #d4e2e2;
    }
  }

  &-title {
    font-size: 18px !important;
    font-weight: 500;
    text-transform: uppercase;
    color: $navy-solid !important;
  }

  &-input {
    background-color: $discussion-color !important;
    padding: 8px 8px 0px 8px;
    // padding-bottom: 8px;
  }

  ::-webkit-scrollbar {
    width: auto;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #eaf1f9;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0px 0px 10px 0px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
