@import "../styles/variables";

.stats-card {
  width: 210px;
  height: 130px;
  position: relative;
  @media (max-width: 600px) {
    width: 100%;
    height: 180px;
  }
}
.stats-card-show {
  width: 210px;
  height: 130px;
  position: relative;
  @media (max-width: 600px) {
    width: 100%;
    height: 180px;
  }
}

.stats-card:hover .hover-container {
  opacity: 1;
}

.stats-card-show .hover-container {
  opacity: 1;
}

.stats-card:hover .stats-container {
  opacity: 1;
}

.stats-card-show .stats-container {
  opacity: 1;
}

.stats-card:hover .interactions-container {
  opacity: 1;
}

.stats-card-show .interactions-container {
  opacity: 1;
}

.hover-container {
  transition: all 0.15s ease-in;
  opacity: 1;
  width: 100%;
  height: 48px;
  background-color: $blue-dianne;
  position: absolute;
  bottom: 0px;
  left: 0;
}
.stats-container {
  transition: all 0.15s ease-in;
  opacity: 0;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0px;
}
.interactions-container {
  transition: all 0.15s ease-in;
  opacity: 0;
  width: 100%;
  height: 15%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $blue-dianne;
}

.react-pdf__Document {
  background-color: transparent !important;
}
