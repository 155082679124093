.button {
  &-reaction-button {
    border-radius: 7px !important;
    margin-right: 6px !important;
    background-color: $green-solid !important;
    max-height: 24px;
    border: 1px solid white !important;
    &__dislike {
      background-color: $red-light !important;
    }
    &__answer_small_light {
      background-color: $answer-color !important;
      color: $blue-dianne !important;
      border-color: $blue-dianne !important;
    }
    &__answer_small_dark {
      color: #f5f5f5 !important;
      background-color: #264c65 !important;
      border-color: #f5f5f5 !important;
    }
    &__answer_large_light {
      background-color: $answer-color !important;
      border-color: $blue-dianne !important;
      min-width: 135px !important;
      color: $blue-dianne !important;
      font-size: 12px !important;
      height: 26px !important;
      margin-top: 10px !important;
      @media (max-width: $mobile) {
        margin-top: 0px !important;
      }
      @media (max-width: $tablet) {
        margin-top: 0px !important;
      }
    }
    &__answer_large_dark {
      background-color: #264c65 !important;
      min-width: 135px !important;
      color: #f5f5f5 !important;
      font-size: 12px !important;
    }
    &__discussions {
      background-color: $discussion-color !important;
      border-color: white !important;
      min-width: 135px !important;
      font-size: 12px !important;
      height: 26px !important;
      // margin-top: 10px !important;
      @media (max-width: $mobile) {
        margin-top: 0px !important;
      }
    }
  }
  &-reaction-icon {
    color: white;
    margin-right: -20px;
    margin-top: 15px;
    &__dislike {
      margin-right: 0px;
      margin-left: -10px;
      margin-top: -15px;
    }
    &__answer {
      margin-right: -20px;
      margin-top: 15px;
    }
  }
  &-reaction-label {
    vertical-align: middle;
    font-size: 12px;
  }
}
