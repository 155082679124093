@import "./app";
@import "./variables";
@import "./login-layout";
@import "./login";
@import "./register-teacher";
@import "./about";
@import "./teacher-layout";
@import "./teacher-sidebar";
@import "./student-layout";
@import "./student-sidebar";
@import "./my-courses";
@import "./my-lectures";
@import "./grid-card";
@import "./create-course";
@import "./create-lecture";
@import "./header";
@import "./class-status";
@import "./slides";
@import "./statistics";
@import "./stats";
@import "./stats-card";
@import "./free-layout";
@import "./slide_stats_bar";
@import "./presentation";
@import "./pick-card";
@import "./anon-card";
@import "./remote";
@import "./waiting-room";
@import "./progress-bar";
@import "./remote-item";
@import "./download-password";
@import "./statistic-card";
@import "./question-card";
@import "./question-answers";
@import "./lecture-questions";
@import "./multiline-question-answer";
@import "./discussion-dialog";
@import "./button";
@import "./answers-card";
@import "./discussions-card";
@import "./more-less-text";
@import "./settings";
@import "./refresh";
@import "./statistics-admin";

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

* {
  font-family: "Montserrat" !important;
  font-weight: 400;
  margin: 0;
}

.btn {
  margin: 0 !important;
  min-width: 0 !important;
}

.card {
  background: transparent !important;
  box-shadow: none;
}

.ac-bordered-white {
  border: 1px solid white !important;
}

.ac-color-white {
  color: white !important;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-tabs-scrollbar {
  ::-webkit-scrollbar {
    width: auto;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #eaf1f9;
    border-radius: 5px 5px 5px 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px 5px 5px 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
